import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import YoutubeVideo9008Unpacking from 'components/Quick_Installation/After_Unpacking/YoutubeVideoCard9008Unpacking';
import YoutubeVideo8015Unpacking from 'components/Quick_Installation/After_Unpacking/YoutubeVideoCard8015Unpacking';
import YoutubeVideoCardLenseAdjust from 'components/Quick_Installation/After_Unpacking/YoutubeVideoCardLenseAdjust';
import YoutubeVideoCardHeater from 'components/Quick_Installation/After_Unpacking/YoutubeVideoCardHeater';
import YoutubeVideoCardCable from 'components/Quick_Installation/After_Unpacking/YoutubeVideoCardCable';
import YoutubeVideoCardSD01 from 'components/Quick_Installation/After_Unpacking/YoutubeVideoCardSD01';
import YoutubeVideoCardSD02 from 'components/Quick_Installation/After_Unpacking/YoutubeVideoCardSD02';
import YoutubeVideoCardSD03 from 'components/Quick_Installation/After_Unpacking/YoutubeVideoCardSD03';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/After_Unpacking/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "After Unpacking",
  "path": "/Quick_Installation/After_Unpacking/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "After Unpacking",
  "image": "./QI_SearchThumb_AfterUnpackingYourIPCamera.png",
  "social": "/images/Search/QI_SearchThumb_AfterUnpackingYourIPCamera.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-After_Unpacking_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='After Unpacking' dateChanged='2017-09-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='After Unpacking' image='/images/Search/QI_SearchThumb_AfterUnpackingYourIPCamera.png' twitter='/images/Search/QI_SearchThumb_AfterUnpackingYourIPCamera.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Nach_dem_Auspacken/' locationFR='/fr/Quick_Installation/After_Unpacking/' crumbLabel="After Unpacking" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "after-unpacking",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#after-unpacking",
        "aria-label": "after unpacking permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`After Unpacking`}</h1>
    <h3 {...{
      "id": "table-of-content",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#table-of-content",
        "aria-label": "table of content permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Table of Content`}</h3>
    <OutboundLink to="https://install.instar.com" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink"><EuiButton fill color="danger" style={{
        float: 'right'
      }} mdxType="EuiButton">Installation Wizard</EuiButton></OutboundLink>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#unboxing"
        }}>{`Unboxing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#lense-adjustment"
        }}>{`Lense Adjustment`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#heater-installation"
        }}>{`Heater Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#connection-cable-removal-or-extension"
        }}>{`Connection Cable Removal or Extension`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sd-card-upgrade"
        }}>{`SD Card Upgrade`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "unboxing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#unboxing",
        "aria-label": "unboxing permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Unboxing`}</h2>
    <YoutubeVideo9008Unpacking mdxType="YoutubeVideo9008Unpacking" />
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideo8015Unpacking mdxType="YoutubeVideo8015Unpacking" />
    <h2 {...{
      "id": "lense-adjustment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#lense-adjustment",
        "aria-label": "lense adjustment permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lense Adjustment`}</h2>
    <p>{`Further information can be found in our online user manuals for the models `}<a parentName="p" {...{
        "href": "/en/Products/Outdoor_Cameras/IN-9008/"
      }}>{`IN-9008 FULL HD`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Products/Outdoor_Cameras/IN-8015/"
      }}>{`IN-8015 FULL HD`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Products/Outdoor_Cameras/IN-7011/"
      }}>{`IN-7011 HD`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Products/Outdoor_Cameras/IN-6014/"
      }}>{`IN-6014 HD`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Products/Outdoor_Cameras/IN-6012/"
      }}>{`IN-6012 HD`}</a>{` `}<a parentName="p" {...{
        "href": "/en/Products/Outdoor_Cameras/IN-6001/"
      }}>{`IN-6001 HD`}</a>{` `}<a parentName="p" {...{
        "href": "/en/Products/Outdoor_Cameras/IN-5907/"
      }}>{`IN-5907 HD`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Products/Outdoor_Cameras/IN-5905/"
      }}>{`IN-5905 HD`}</a>{`.`}</p>
    <YoutubeVideoCardLenseAdjust mdxType="YoutubeVideoCardLenseAdjust" />
    <h2 {...{
      "id": "heater-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#heater-installation",
        "aria-label": "heater installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Heater Installation`}</h2>
    <YoutubeVideoCardHeater mdxType="YoutubeVideoCardHeater" />
    <h2 {...{
      "id": "connection-cable-removal-or-extension",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#connection-cable-removal-or-extension",
        "aria-label": "connection cable removal or extension permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connection Cable Removal or Extension`}</h2>
    <YoutubeVideoCardCable mdxType="YoutubeVideoCardCable" />
    <h2 {...{
      "id": "sd-card-upgrade",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sd-card-upgrade",
        "aria-label": "sd card upgrade permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SD Card Upgrade`}</h2>
    <YoutubeVideoCardSD01 mdxType="YoutubeVideoCardSD01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideoCardSD02 mdxType="YoutubeVideoCardSD02" />
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideoCardSD03 mdxType="YoutubeVideoCardSD03" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      